import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import firebase from "firebase/compat/app";
import config from "./config";
import TagManager from 'react-gtm-module';
import "./assets/stylesheets/application.css";
import Routes from "./components/Routes";
import themeSettings from "./components/base/settings";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER_ID
}

TagManager.initialize(tagManagerArgs)

export default function App() {
  useEffect(() => {
    firebase.initializeApp(config);
  }, []);

  return (
    <ThemeProvider theme={themeSettings}>
      <Router>
        <React.Fragment>
          <Helmet>
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cloud.typography.com/6693092/7810772/css/fonts.css"
            />
          </Helmet>
          <Routes />
        </React.Fragment>
      </Router>
    </ThemeProvider>
  );
}
