module.exports = {
  colors: {
    blue: "#66767A",
    blueS: "#0C3642",
    blueT: "#a6bac0",
    blueTT: "#d6dfe2",
    blueTTT: "#F2F4F5",
    gray: "#6a6a6a",
    grayS: "#444444",
    grayT: "#dddddd",
    grayTT: "#eaeaea",
    grayTTT: "#f2f2f2",
    grayTTTT: "#fafbfb",
    green: "#99af86",
    orange: "#daa080",
    orangeT: "#e4b8a0",
    orangeTT: "#eccfbf",
    orangeTTT: "#f6e7df",
    red: "#c55947",
    redS: "#ac4b3b",
  },
  easings: {
    cubicOut: "cubic-bezier(0.33, 1, 0.68, 1)",
    quintOut: "cubic-bezier(0.23, 1, 0.32, 1)",
  },
  fonts: {
    base: "'Whitney SSm A', 'Whitney SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;",
    heading:
      "'Mercury Display A', 'Mercury Display B', Georgia, 'Times New Roman', Times, serif;",
  },
  scale: {
    base: "16px",
    up6: "4.2358em",
    up5: "3.33em",
    up4: "2.6179em",
    up3: "2.0581em",
    up2: "1.618em",
    up1: "1.272em",
    down1: "0.7861em",
    down2: "0.618em",
    down3: "0.4856em",
    down4: "0.382em",
    down5: "0.3em",
    down6: "0.2361em",
    down7: "0.1856em",
  },
};
