import React from "react";
import { Redirect, Route } from "react-router-dom";
import Loadable from "react-loadable";
import styled from "styled-components";
import { prop } from "styled-tools";

import loader from "assets/images/loader.gif";

/*
 * Styles
 */

const Button = styled.button`
  background: none;
  border: 0;
  color: ${prop("theme.colors.blue")};
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
  transition: color 200ms ease;
  &:hover,
  &:focus {
    color: ${prop("theme.colors.blueS")};
  }
`;

const Container = styled.div`
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
`;

/*
 * Component
 */

function LoadingComponent(props) {
  if (props.error) {
    return (
      <Container>
        There was an error while loading the data.{" "}
        <Button onClick={props.retry}>Retry</Button>
      </Container>
    );
  } else if (props.timedOut) {
    return (
      <Container>
        The data is taking longer than usual to load.{" "}
        <Button onClick={props.retry}>Retry?</Button>
      </Container>
    );
  } else if (props.pastDelay) {
    return (
      <Container>
        <img src={loader} alt="Loading" />
      </Container>
    );
  } else {
    return null;
  }
}

const DataExplorer = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'DataExplorer' */ "pages/DataExplorer"),
  loading: LoadingComponent,
  delay: 300,
  timeout: 30000,
});

const KeyFindings = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'KeyFindings' */ "pages/KeyFindings"),
  loading: LoadingComponent,
  delay: 300,
  timeout: 30000,
});

const UKWelcome = Loadable({
  loader: () => import(/* webpackChunkName: 'UKWelcome' */ "pages/UKWelcome"),
  loading: LoadingComponent,
  delay: 300,
  timeout: 30000,
});

const CreateGroupSurvey = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'CreateGroupSurvey' */ "pages/CreateGroupSurvey"
    ),
  loading: LoadingComponent,
  delay: 300,
  timeout: 30000,
});

const GroupSurveys = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'GroupSurveys' */ "pages/GroupSurveys"),
  loading: LoadingComponent,
  delay: 300,
  timeout: 30000,
});

const GroupSurveyResults = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'GroupSurveyResults' */ "pages/GroupSurveyResults"
    ),
  loading: LoadingComponent,
  delay: 300,
  timeout: 30000,
});

const Routes = () => (
  <React.Fragment>
    <Route
      exact
      path="/"
      render={(props) => <KeyFindings {...props} locale="us" />}
    />
    <Route
      exact
      path="/uk"
      render={(props) => <UKWelcome {...props} locale="uk" />}
    />
    <Route
      path="/data-explorer/:year?/:id?"
      render={(props) => <DataExplorer {...props} locale="us" />}
    />
    <Route
      path="/uk/data-explorer/:category?/:year?/:id?"
      render={(props) => <DataExplorer {...props} locale="uk" />}
    />
    <Route
      path="/takesurvey"
      render={() => (
        <Redirect to="/data-explorer?AGE=30&MF=14&REGION=30&DENSITY=62&EDUCATION=62&INCOME=254&MARITAL=126&ETHNICITY=62&RELTRAD=62&EVB=6&ATTENDANCE=254&survey=1" />
      )}
    />
    <Route
      path="/uk/takesurvey"
      render={() => (
        <Redirect to="/uk/data-explorer?AGE=30&MF=14&REGION=30&EDUCATION=62&INCOME=254&MARITAL=126&ETHNICITY=62&RELTRAD=62&ATTENDANCE=254&survey=1" />
      )}
    />

    <Route
      path="/create-group-survey"
      render={(props) => <CreateGroupSurvey {...props} />}
    />

    <Route
      path="/group-surveys"
      render={(props) => <GroupSurveys {...props} />}
    />

    <Route
      exact
      path="/groups/:id/results"
      render={(props) => <GroupSurveyResults {...props} />}
    />

    <Route
      exact
      path="/groups/:id"
      render={(props) => {
        const { id } = props.match.params;
        const currentGroupId = localStorage.getItem("groupId");

        if (id !== currentGroupId) {
          localStorage.setItem("groupId", id);
          localStorage.removeItem("submissionId");

          Object.keys(localStorage).forEach((key) => {
            if (key.match(/^Q[0-9]{2}_[0-9]+/)) {
              localStorage.removeItem(key);
            }
          });
        }

        return (
          <Redirect
            to={`/data-explorer?AGE=30&MF=14&REGION=30&DENSITY=62&EDUCATION=62&INCOME=254&MARITAL=126&ETHNICITY=62&RELTRAD=62&EVB=6&ATTENDANCE=254&survey=1`}
          />
        );
      }}
    />

    <Route exact path="/groupsurveys">
      <Redirect to="/group-surveys"/>
    </Route>

    <Route exact path="/groupsurvey">
      <Redirect to="/group-surveys"/>
    </Route>
  </React.Fragment>
);

export default Routes;
